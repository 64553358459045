import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logolight from '../../assets/images/logotop.png';
import logodark from '../../assets/images/logotop.png';

const Register = props => {
    document.title = "Registro | TicketControl - Monitor";

    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Ingresa tu email"),
            username: Yup.string().required("Ingresa tu Nombre de Usuario"),
            password: Yup.string().required("Ingresa tu Contraseña"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    const { user, registrationError } = useSelector(state => ({
        user: state.account.user,
        registrationError: state.account.registrationError,
    }));

    // handleValidSubmit
    // const handleValidSubmit = values => {
    //   dispatch(registerUser(values));
    // };

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    return (
        <div className="bg-pattern" style={{ height: "100vh" }}>
            <div className="bg-overlay"></div>
            <div className="account-pages pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <Link to="/" className="">
                                            <img src={logodark} alt="" height="50" className="auth-logo logo-dark mx-auto" />
                                            <img src={logolight} alt="" height="50" className="auth-logo logo-light mx-auto" />
                                        </Link>
                                    </div>

                                    <h4 className="font-size-18 text-muted text-center mt-2">Regístrate</h4>
                                    <p className="text-muted text-center mb-4">Crea tu cuenta ahora.</p>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {user &&  (
                                            <div>
                                                <Alert color="success">
                                                    Se ha registrado el Usuario con éxito.
                                                </Alert>
                                                <Link to="/login" className="d-grid mt-4">
                                                    <button className="btn btn-primary waves-effect waves-light">Ingresar</button>
                                                </Link>
                                            </div>
                                        )}

                                        {registrationError && registrationError ? (
                                            <Alert color="danger"><div>{registrationError}</div></Alert>
                                        ) : null}

                                        {!user && <Row>
                                            <Col md={12}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Label className="form-label">Nombre de Usuario</Label>
                                                    <Input
                                                        name="username"
                                                        type="text"
                                                        placeholder="Enter username"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        invalid={
                                                            validation.touched.username && validation.errors.username ? true : false
                                                        }
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.username}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Label className="form-label">Contraseña</Label>
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="term-conditionCheck" />
                                            <label className="form-check-label fw-normal" htmlFor="term-conditionCheck">Acepto los <Link to="#" className="text-primary">Términos y condiciones</Link></label>
                                        </div> */}
                                                <div className="d-grid mt-4">
                                                    <button className="btn btn-primary waves-effect waves-light" type="submit">Registrarme</button>
                                                </div>
                                            </Col>
                                        </Row>}
                                    </Form>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p className="text-white-50">Ya tienes una cuenta ?<Link to="/login" className="fw-medium text-primary"> Ingresa </Link> </p>
                                <p className="text-white-50"> © {new Date().getFullYear()} TicketControl Monitor - TicketControl Dev</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Register;
