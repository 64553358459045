import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCompany } from "../../store/companies/actions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { firestore } from "../../firebase";
import { doc, getFirestore, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';


import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardImg,
    CardText,
    CardHeader,
    CardImgOverlay,
    CardFooter,
    CardDeck,
    Container,
    Button,
    Modal
} from "reactstrap";


const CompanyOptions = (props) => {
    document.title = "Estacionamientos | TicketControl - Monitor";
    const dispatch = useDispatch();
    const  [companies, setCompanies] = useState([]);
    const [modal, setmodal] = useState(false);
    const [currentCompany, setCurrentCompany] = useState({});
    const usersRef = collection(firestore, 'users');
    const [userEmail, setUserEmail] = useState(null)

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setUserEmail(obj.email);
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setUserEmail(obj.email);
            }
        }
    }, []);


    useEffect(() => {
        //fetch companies
        setCompanies(props.companies);
    }, [props.companies]);

    if (!props.companies || props.companies.length == 0) {
        return null
    }

    const deleteCompany_confirm = (company) => {
        setCurrentCompany(company)
        setmodal(true)
    }

    const toggleModal = () => { 
        setmodal(!modal)
    }

    const deleteCompanyRun = () => {
        setmodal(false)
        // dispatch(deleteCompany(currentCompany))
        deleteFromFirestore(currentCompany)
        
    }


    const reloadPage = () => {
        window.location.reload();
    };

    const deleteFromFirestore = async (company) => {
        const db = getFirestore();
        const key = company.id
        const email_for_delete = userEmail;

        // Referencia a la subcolección 'auth' del documento específico en 'users'
        const authCollectionRef = collection(db, 'users', key, 'auth');
       
        // Crea una consulta para encontrar el documento en la subcolección 'auth' donde el campo 'email' coincide con 'email_for_delete'
        const q = query(authCollectionRef, where('email', '==', email_for_delete));

        try {
            // Ejecuta la consulta
            const querySnapshot = await getDocs(q);

            // Si se encuentra el documento, procede a eliminarlo
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
                console.log('Documento eliminado con éxito:', doc.id);
                reloadPage()
            });

        } catch (error) {
            console.error('Error eliminando el documento:', error);
        }
        


    }




    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    {/* <Breadcrumbs title="Estacionamientos" /> */}

                   

                    <Row>
                        {companies.map((company) => (
                           
                            <Col xs={12} sm={12} md={6} lg={4}>
                            <Card>
                                    <CardHeader className="h4 bg-transparent border-bottom text-uppercase">{company.name}</CardHeader>
                                <CardBody>
                                    <CardTitle className="mt-0">
                                         Key: {company.id}
                                    </CardTitle>
                                    <CardText>
                                            Key es la identificación la licencia establecida para la computadora en la que se encuentra instalado TicketControl.<br></br>
                                            <br></br>
                                            <strong>Ubicación:</strong> {company.address} - {company.city}<br></br><br></br>

                                            <p>Puedes eliminar este estacionamiento de tu cuenta en TicketControl Monitor haciendo click en el siguiente botón. Puedes incorporarla nuevamente desde TicketControl.</p>
                                    </CardText>
                                        <Button className="btn btn-danger waves-effect waves-light w-100" onClick={() => deleteCompany_confirm(company)}>
                                            <i className="ri-close-line align-middle me-2"></i>{" "}
                                        Eliminar
                                        </Button>
                                </CardBody>
                            </Card>
                            </Col>
                        ))}

                        
                        </Row>

                
                    <Modal
                        isOpen={modal}
                        toggle={() => {
                            toggleModal()
                        }}
                        centered
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">
                                Confirma que deseas eliminar este estacionamiento de tu cuenta en TicketControl Monitor
                            </h5>
                            {/* <button
                                type="button"
                                onClick={() => {
                                    setmodal(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body">
                            <p>
                                Esta acción no es crítica. Si deseas volver a incorporar este estacionamiento en tu cuenta de TicketControl Monitor, solo debes ingresar TicketControl en la PC de tu estacionamiento y agregar tu cuenta nuevamente en Configuración -> Ajustes -> Monitor.
                            </p>
                            
                         
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    toggleModal()
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Cancelar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger "
                                onClick={() => {
                                    deleteCompanyRun()
                                }}
                            >
                                Si, eliminar.
                            </button>
                        </div>

                    </Modal>


                </Container>
            </div>
        </>
    );
}



const mapStateToProps = (state) => {
    return {
        companies: state.companies.companies,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyOptions);