// ESTA TABLA ES PARA LA VERSIONES A PARTIR DE LA 1.2.50

import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { map } from 'lodash';
import numeral from 'numeral'
import moments from 'moment'
moments.locale('es')



const TableCCVehicles = (props) => {
    
    const columns = [

        {
            name: <span className='font-weight-bold fs-13'>ID</span>,
            selector: row => row.id,
            sortable: true,
            width: "90px",
            cellExport: row => row.id,
        },
      
        {
            name: <span className='font-weight-bold fs-13'>Matrículas</span>,
            selector: row => <span style={{ fontWeight: 700 }}>{row.plate ? "#"+row.plate.toUpperCase() : 'Sin Matrícula'}</span>,
            sortable: true,
            wrap: true,
            cellExport: row => row.plate ? "#" + row.plate.toUpperCase() : 'Sin Matrícula',
        },

        {
            name: <span className='font-weight-bold fs-13'>Marca/Modelo</span>,
            selector: row => row.brand ? row.brand.toUpperCase() : 'Sin Marca',
            sortable: true,
            wrap: true,
            cellExport: row => row.brand ? row.brand.toUpperCase() : 'Sin Marca',
        },
        
        {
            name: <span className='font-weight-bold fs-13'>Categoría</span>,
            selector: row => row.plate ? row.category_name.toUpperCase() : 'Sin Categoría',
            sortable: true,
            wrap: true,
            cellExport: row => row.plate ? row.category_name.toUpperCase() : 'Sin Categoría',
        },

        {
            name: <span className='font-weight-bold fs-13'>Cliente</span>,
            selector: row => <span style={{ fontWeight: 700 }}>{row.fullName.toUpperCase()}</span>,
            sortable: true,
            wrap: true,
            width: "250px",
            cellExport: row => row.fullName,

        },

        {
            name: <span className='font-weight-bold fs-13'>Ubicación</span>,
            selector: row => row.floor_name ? row.floor_name.toUpperCase() : ' - ',
            sortable: true,
            wrap: true,
            cellExport: row => row.floor_name ? row.floor_name.toUpperCase() : ' - ',
        },

        // {
        //     name: <span className='font-weight-bold fs-13'>Presente</span>,
        //     selector: row => row.is_present == 0 ? 'AUSENTE' : 'PRESENTE',
        //     sortable: true,
        //     wrap: true,
        //     cellExport: row => row.category_name,
        // },
      
        
        {
            name: <span className='font-weight-bold fs-13'>Tarifa</span>,
            selector: row => row.timelapse_label,
            sortable: true,
            wrap: true,
            cellExport: row => row.timelapse_label,
        },
      
        {
            name: <span className='font-weight-bold fs-13'>Valor Mensual</span>,
            selector: row => {

                if (row.custom_value && row.custom_value > 0) {
                    return <span><strong>{numeral(row.custom_value).format('$ 0,0')}</strong> <span className="badge badge-soft-success  " style={{ fontSize: '0.8em' }}> T.ESP </span></span>
                }
                else {
                    return <span><strong>{numeral(row.rate_value).format('$ 0,0')}</strong></span>
                }
                
             }, 
            
            // (<span><strong>{row.custom_value && row.custom_value > 0 ? numeral(row.custom_value).format('$ 0,0') + <span className="badge badge-soft-danger  " style={{ fontSize: '0.8em' }}> VENCIDO </span> : numeral(row.rate_value).format('$ 0,0')}</strong></span>),
            sortable: true,
            cellExport: row => row.custom_value && row.custom_value > 0 ? row.custom_value : row.rate_value
        },
     
       
    ];


    if (!props.cc_vehicles) {
        return (<div>No hay vehículos por el momento</div>)
    }
   
    
    // console.log('xxxx', props.cc_vehicles)
    let items = []
    props.cc_vehicles.forEach((cc, index) => {

        const item = {
            id: cc['id'],
            plate: cc['plate'],
            brand: cc['brand'],
            category_name: cc['category_name'],
            fullName: cc['fullName'],
            floor_name: cc['place_name'] ? cc['floor_name'] + " - " + cc['place_name'] : ' - ',
            presence: cc['is_present'],
            timelapse_label: cc['timelapse_label'],
            custom_value: cc['custom_value'],
            rate_value: cc['rate_value'],
        }

        items.push(item)
       

    })

    const data = items

    console.log('DATAAAAAAAAAAAAAAAAA', data)

    const tableData = {
        columns,
        data
      };

      const customMessages = {
        // Mensajes del encabezado de la tabla
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar CSV',
          print: 'Imprimir',
          viewColumns: 'Ver columnas',
          filterTable: 'Filtrar tabla',
        },
        // Otros mensajes personalizados que desees cambiar
        // ...
      };

    return (
        <div>
            {/* Input de búsqueda */}
  
    <DataTableExtensions {...tableData} exportHeaders={false} filterPlaceholder='Filtrar'>
            <DataTable
                title={'Mensuales ('+String(data.length)+')'}
                // columns={columns}
                // data={data}
                pagination
                highlightOnHover
                paginationPerPage={25}
                className="data-table"
                // noHeader={true}
                paginationDefaultPage={1}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página:',
                    rangeSeparatorText: 'de',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All',
                    toolbar: {
                        search: 'Buscar',
                        downloadCsv: 'Descargar CSV',
                        print: 'Imprimir',
                        viewColumns: 'Ver columnas',
                        filterTable: 'Filtrar tabla',
                      },
                    
                }}
                customMessages={customMessages}

                // subHeader
                // subHeaderComponent={
                //     <FilterComponent
                //       onFilter={handleFilterChange}
                //       onClear={handleClear}
                //       filterText={filterText}
                //     />
                //   }
                // paginationResetDefaultPage={resetPaginationToggle}
                noDataComponent={<div className="p-4 text-center">No hay datos</div>}
                persistTableHead
            />
            </DataTableExtensions>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cc_vehicles: state.data.cc_vehicles
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableCCVehicles)