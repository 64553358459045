import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { connect } from 'react-redux';
import { Container, Row, Col, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import ResumeCash from "../Dashboard/ResumCash";

import numeral from 'numeral'
import moments from 'moment'
moments.locale('es')



const TableCurrentCash = (props) => {

    const [selectedButton, setSelectedButton] = useState(0);
    const [currentCash, setCurrentCash] = useState([]);
    const [allCash, setAllCash] = useState([]);
    const [resumes, setResumes] = useState([]);


    const handleButtonClick = (index) => {
        setSelectedButton(index);
        setCurrentCash(allCash[index])
    };

    useEffect(() => {
        const items = [...props.cash].reverse()
        setAllCash(items)
        setCurrentCash(items[0])
    }, [props.cash])


    useEffect(()=>{

        const items = [...props.resumes].reverse()
        setResumes(items)

    }, [props.resumes])


    const columns = [

        {
            name: <span className='font-weight-bold fs-13'>ID</span>,
            selector: row => row.id,
            sortable: true,
            width: "90px", 
            cellExport: row => row.id, 
        },
        {
            name: <span className='font-weight-bold fs-13'>FECHA</span>,
            selector: row => row.date,
            sortable: true,
            width: "120px", 
            cellExport: row => row.date, 
        },
        {
            name: <span className='font-weight-bold fs-13'>HORA</span>,
            selector: row => row.time,
            sortable: true,
            width: "90px", 
            cellExport: row => row.time, 
        },
        {
            name: <span className='font-weight-bold fs-13'>DESCRIPCION</span>,
            selector: row => <span style={{ fontWeight: 700 }}>{row.desc}</span>,
            sortable: true, 
            cellExport: row => row.desc, 
        },

        {
            name: <span className='font-weight-bold fs-13'>OPERADOR</span>,
            selector: row => row.operator,
            sortable: true,
            width: "180px", 
            cellExport: row => row.operator, 
        },
        {
            name: <span className='font-weight-bold fs-13'>F. de PAGO</span>,
            selector: row => row.payment,
            sortable: true,
            width: "120px", 
            cellExport: row => row.payment, 
        },
        {
            name: <span className='font-weight-bold fs-13'>IMPORTE</span>,
            selector: row => row.value,
            sortable: true,
            width: "120px", 
            cellExport: row => row.value, 
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>Create By</span>,
        //     selector: row => row.createdBy,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Create Date</span>,
        //     selector: row => row.createDate,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Status</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.status) {
        //             case "Re-open":
        //                 return <span className="badge badge-soft-info"> {cell.status} </span>;
        //             case "On-Hold":
        //                 return <span className="badge badge-soft-secondary"> {cell.status} </span>;
        //             case "Closed":
        //                 return <span className="badge badge-soft-danger"> {cell.status} </span>;
        //             case "Inprogress":
        //                 return <span className="badge badge-soft-warning"> {cell.status} </span>;
        //             case "Open":
        //                 return <span className="badge badge-soft-primary"> {cell.status} </span>;
        //             case "New":
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //             default:
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //         }
        //     },
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Priority</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.priority) {
        //             case "High":
        //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        //             case "Medium":
        //                 return <span className="badge bg-info"> {cell.priority} </span>;
        //             case "Low":
        //                 return <span className="badge bg-success"> {cell.priority} </span>;
        //             default:
        //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        //         }
        //     },
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Action</span>,
        //     sortable: true,
        //     cell: () => {
        //         return (
        //             <UncontrolledDropdown className="dropdown d-inline-block">
        //                 <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
        //                     <i className="ri-more-fill align-middle"></i>
        //                 </DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <DropdownItem href="#!"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
        //                     <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
        //                     <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         );
        //     },
        // },
    ];



    if (allCash.length == 0) return <div className="p-4 text-center">No hay datos</div>

    let items = []

    currentCash.forEach((item, index) => {
        const data = {
            id: item['id'],
            date: moments(item['date']).format('DD/MM/YYYY'),
            time: moments(item['date']).format('HH:mm'),
            desc: item['description'],
            operator: item['user_fullName'],
            payment: item['fpay'],
            value: numeral(item['value']).format('$0,0.00'),
        }

        items.push(data)

    })

    const data = items



    const tableData = {
        columns,
        data
    };

    const customMessages = {
        // Mensajes del encabezado de la tabla
        toolbar: {
            search: 'Buscar',
            downloadCsv: 'Descargar CSV',
            print: 'Imprimir',
            viewColumns: 'Ver columnas',
            filterTable: 'Filtrar tabla',
        },
        // Otros mensajes personalizados que desees cambiar
        // ...
    };

   
    return (
       
            <Row>
                <Col xs ={12}><ResumeCash data = {resumes[selectedButton]}/></Col>

                <Col xl={2}  lg={2} md={12}  sm={12} xs={12}>
                        <Row style={{marginTop:60}}>
                        
                            {allCash.map((item, index) => {
                                let d = item[0];
                                return (
                                    <Col  key={index} lg={12} md={4} sm={6} xs={12}>
                                    <div key={index} style={{width:'100%'}} className=''>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="vbtn-radio"
                                            id={`vbtn-radio${index}`}
                                            autoComplete="off"
                                            checked={selectedButton === index}
                                            onChange={() => handleButtonClick(index)}
                                            style={{width:'100%'}}

                                        />
                                        <label className="btn btn-outline-primary btn-block text-uppercase " htmlFor={`vbtn-radio${index}`} style={{width:'100%'}}>
                                            {d.user_fullName}
                                            <br />
                                            <small>{moments(d.date).format('DD/MM/YYYY HH:MM')}</small>
                                        </label>
                                    </div>
                                    </Col>
                                );
                            })}

                       
                        </Row>
                </Col>

                <Col xl={10} lg={10} md={12} sm={12} xs={12} >
                    {/* Input de búsqueda */}
                    <DataTableExtensions {...tableData} exportHeaders={false} filterPlaceholder='Filtrar' >
                        <DataTable
                            title={'Caja actual (' + String(data.length) + ')'}
                            // columns={columns}
                            // data={data}
                            pagination
                            highlightOnHover
                            paginationPerPage={15}
                            className="data-table"
                            // noHeader={true}
                            paginationDefaultPage={1}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Filas por página:',
                                rangeSeparatorText: 'de',
                                noRowsPerPage: false,
                                selectAllRowsItem: false,
                                selectAllRowsItemText: 'All',
                                toolbar: {
                                    search: 'Buscar',
                                    downloadCsv: 'Descargar CSV',
                                    print: 'Imprimir',
                                    viewColumns: 'Ver columnas',
                                    filterTable: 'Filtrar tabla',
                                },

                            }}
                            customMessages={customMessages}

                            // subHeader
                            // subHeaderComponent={
                            //     <FilterComponent
                            //       onFilter={handleFilterChange}
                            //       onClear={handleClear}
                            //       filterText={filterText}
                            //     />
                            //   }
                            // paginationResetDefaultPage={resetPaginationToggle}
                            noDataComponent={<div className="p-4 text-center">No hay datos</div>}
                            persistTableHead
                        />
                    </DataTableExtensions>
                </Col>
            </Row >
       
    );
};

const mapStateToProps = (state) => {
    return {
        cash: state.cash.cash_items,
        resumes: state.cash.resume_cash_items
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableCurrentCash)