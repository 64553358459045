// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyBP70-y5Q55MU-zBvWXP_ANO6h32W6mDYI",
        authDomain: "ticketcontroldev.firebaseapp.com",
        projectId: "ticketcontroldev",
        storageBucket: "ticketcontroldev.appspot.com",
        messagingSenderId: "345180760148",
        appId: "1:345180760148:web:3e3ef42e4e366f990d25c1",
        measurementId: "G-ERYTT9LM0L"

        
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Obtiene la instancia de Firestore
const firestore = getFirestore(app);
const auth = getAuth(app); 
export { firestore, auth };
