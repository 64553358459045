import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Container, CardTitle } from "reactstrap";
// import { db } from '../../firebase';
import ResumeCash from "./ResumCash";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';

import { setTicketsStats, fetchTicketsStats, setLogins } from "../../store/data/actions";
import { collection, doc, onSnapshot, orderBy, query, where, getDocs, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import moment from 'moment';
import "moment/locale/es";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { set } from "lodash";
import TicketsStats from "../Tickets/ticketstats";
import Ticket from "./lastTickets"

const Dashboard = (props) => {


  document.title = "Dashboard | TicketControl - Monitor";
  const [lastLogin, setLastLogin] = useState(null);
  const [hasTicket, setHasTicket] = useState(false);

  useEffect(() => {
    setLastLogin(props.logins[0]);
  }, [props.logins]);

  useEffect(() => {
    if (props.openedTickets.length > 0) {
      setHasTicket(true);
    } else {
      setHasTicket(false);
    }
  }, [props.openedTickets]);





  const { companies, company } = useSelector((state) => ({
    companies: state.companies.conmpanies,
    company: state.companies.currentCompany,
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="TicketControl" breadcrumbItem="Dashboard" /> */}
          <Row>
            <Col lg={12}>
              <Row >
                <Col  xxl = {5} xl = {5}  lg={5} md={4}  xs ={12} sm={12}  className="d-flex">
                  <Card className='flex-grow-1'>
                    <CardBody>
                      {/* <CardTitle>{company.name}</CardTitle> */}
                      <h2 className='text-primary'><strong>{company.name}</strong></h2>
                      <h3 className=''>{company.address}</h3>
                    </CardBody>
                  </Card>
                </Col>

                {lastLogin &&
                  <Col  xxl = {4} xl = {4} lg={4} md={4} xs ={6} sm={6} className="d-flex">
                    <Card className='flex-grow-1'>
                      <CardBody>
                        <div className="card-title">Cajero actual - Inicio de sesión {moment(lastLogin.created, 'YYYY-MM-DD HH:mm').format('DD/MM HH:mm')}</div>
                        <h2>{lastLogin.fullName}</h2>
                      </CardBody>
                    </Card>
                  </Col>
                }

                {hasTicket == true && <Col xxl = {3} xl={3} lg={3} md={4}  xs ={6} sm={6}  className="d-flex">
                  <Ticket />
                </Col>}

                </Row >
                <Row>

                {/* <h4>Tickets</h4> */}
                <Col xl={12} lg={12} md={12} xs={12} sm={12}  >
                <TicketsStats />
                </Col>


                {props.resume_cash_items && props.resume_cash_items.length > 0 && <Col xl={12} lg={12} md={12} xs={12} sm={12} ><ResumeCash data={props.resume_cash_items[props.resume_cash_items.length - 1]} /></Col>}


              </Row>

            </Col>


          </Row>
        </Container>


      </div >


    </React.Fragment >
  );
};


const mapStateToProps = (state) => {
  return {
    customer_id: state.data.customer_id,
    currentCompany: state.companies.currentCompany,
    logins: state.data.logins,
    resume_cash_items: state.cash.resume_cash_items,
    openedTickets: state.data.openedTickets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

