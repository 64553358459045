const spanishText = {
    // Filtros
    contains: 'Contiene',
    notContains: 'No contiene',
    equals: 'Igual',
    notEqual: 'No igual',
    startsWith: 'Empieza con',
    endsWith: 'Termina con',
    lessThan: 'Menor que',
    lessThanOrEqual: 'Menor o igual que',
    greaterThan: 'Mayor que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'En rango',
    // Herramientas de filtro
    applyFilter: 'Aplicar filtro',
    resetFilter: 'Resetear filtro',
    clearFilter: 'Limpiar filtro',
    // Estado de carga
    loadingOoo: 'Cargando...',
    // Números de página
    page: 'Página',
    more: 'Más',
    to: 'a',
    of: 'de',
    next: 'Siguiente',
    last: 'Último',
    first: 'Primero',
    previous: 'Anterior',
    // Menú
    copy: 'Copiar',
    copyWithHeaders: 'Copiar con cabeceras',
    ctrlC: 'Ctrl+C',
    paste: 'Pegar',
    ctrlV: 'Ctrl+V',
    // Selección
    pinColumn: 'Fijar columna',
    valueAggregation: 'Agregar valor',
    autosizeThiscolumn: 'Autoajustar esta columna',
    autosizeAllColumns: 'Autoajustar todas las columnas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Resetear columnas',
    expandAll: 'Expandir todo',
    collapseAll: 'Colapsar todo',
    toolPanel: 'Panel de herramientas',
    export: 'Exportar',
    csvExport: 'Exportar a CSV',
    excelExport: 'Exportar a Excel (.xlsx)',
    excelXmlExport: 'Exportar a XML (.xml)',
    // Filtro de columnas
    pinLeft: 'Fijar a la izquierda',
    pinRight: 'Fijar a la derecha',
    noPin: 'Sin fijar',
    // Panel de columnas
    columns: 'Columnas',
    rowGroupColumns: 'Columnas de agrupación de filas',
    rowGroupColumnsEmptyMessage: 'Arrastre aquí para agrupar por filas',
    valueColumns: 'Columnas de valor',
    pivotMode: 'Modo pivote',
    groups: 'Grupos',
    values: 'Valores',
    pivots: 'Pivotes',
    valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
    pivotColumnsEmptyMessage: 'Arrastre aquí para pivotar',
    // Otros
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Habilitado',

    // Para la versión Enterprise
    sum: 'Suma',
    min: 'Mínimo',
    max: 'Máximo',
    none: 'Ninguno',
    count: 'Cuenta',
    average: 'Promedio',
    // Y así sucesivamente para todas las cadenas que necesitas traducir...
};

export default spanishText;