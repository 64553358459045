// src/Firebase/db.js

import { storage, firestore } from './firebaseConfig';
import { ref, listAll, getDownloadURL } from "firebase/storage";

// Funciones para listar y obtener URL de descarga de backups
const listBackups = async (clientId) => {
    const storageRef = ref(storage, `${clientId}/`);
    try {
        const result = await listAll(storageRef);
        return result.items.map(itemRef => itemRef.name);
    } catch (error) {
        console.error("Error listing backups:", error);
        throw error;
    }
};

const getBackupDownloadURL = async (clientId, fileName) => {
    const fileRef = ref(storage, `${clientId}/${fileName}`);
    try {
        const url = await getDownloadURL(fileRef);
        return url;
    } catch (error) {
        console.error("Error getting download URL:", error);
        throw error;
    }
};


export { listBackups, getBackupDownloadURL, firestore };