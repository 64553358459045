import React, { useEffect } from "react";
import Routes from "./Routes/index";

// Import Scss
import './assets/scss/theme.scss';
import { useLocation } from 'react-router-dom';
// Fake Backend 
// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// Firebase
// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"
import ReactGA from "react-ga4";
ReactGA.initialize('G-JMJCE53GLE');


const firebaseConfig = {
  apiKey: "AIzaSyBP70-y5Q55MU-zBvWXP_ANO6h32W6mDYI",
  authDomain: "ticketcontroldev.firebaseapp.com",
  projectId: "ticketcontroldev",
  storageBucket: "ticketcontroldev.appspot.com",
  messagingSenderId: "345180760148",
  appId: "1:345180760148:web:3e3ef42e4e366f990d25c1",
  measurementId: "G-ERYTT9LM0L"
}

// init firebase backend
initFirebaseBackend(firebaseConfig)


function App() {
  return (
    <React.Fragment>
      <TrackPageViews />
      <Routes />
    </React.Fragment>
  );
}



const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      ReactGA.send("pageview");
    }, 30 * 60 * 1000); // 30 minutos en milisegundos

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
  }, [location]);

  return null;
}


export default App;



// import React, { useEffect } from "react";
// import Routes from "./Routes/index";
// import ReactGA from "react-ga4";
 
// import './assets/scss/theme.scss';

// import { useLocation } from 'react-router-dom';
// import 'react-toastify/dist/ReactToastify.css';

// ReactGA.initialize('G-JMJCE53GLE');

// const App = () => {




//   return (
//     <React.Fragment>
//       <TrackPageViews />
//       <Routes />
//     </React.Fragment>
//   );
// }



// const TrackPageViews = () => {
//   const location = useLocation();

//   useEffect(() => {
//     const interval = setInterval(() => {
//       ReactGA.send("pageview");
//     }, 30 * 60 * 1000); // 30 minutos en milisegundos

//     return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
//   }, [location]);

//   return null;
// }


// export default App;





