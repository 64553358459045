import { SET_DATA_CASH, ADD_DATA_CASH } from "./actionTypes"

export const setDataCash = (data) => {
  return {
    type: SET_DATA_CASH,
    payload: data
  };
};


export const addDataCash = (data) => {
    return {
      type: ADD_DATA_CASH,
      payload: data
    };
  };
