import React from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

    const newPage = () => {
        document.title = "New Page | Upzet - React Admin & Dashboard Template";
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="New Page" breadcrumbItem="New Page" />
                        
                            //write Html code or structure

                    </Container>
                </div>
            </>
        );
    }

export default newPage;