import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import Cash from "../Pages/Cash";
import Companies from "../Pages/Companies";
import Tickets from "../Pages/Tickets";
import CC from "../Pages/CC";
import Sessions from "../Pages/Sessions";
import Backups from "../Pages/Backups";




// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import CompaniesOptions from "../Pages/CompaniesOptions";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/customers", component: <Cash /> },
  { path: "/tickets", component: <Tickets /> },
  { path: "/opers", component: <Cash /> },
  { path: "/stats", component: <Cash /> },
  { path: "/cash", component: <Cash /> },
  { path: "/companies", component: <Companies /> },
  { path: "/cc", component: <CC /> },
  { path: "/sessions", component: <Sessions /> },
  { path: "/backups", component: <Backups /> },

  { path: "/userprofile", component: <UserProfile /> },
  { path: "/companiesOptions", component: <CompaniesOptions /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
