import { takeEvery, put, call } from 'redux-saga/effects';



// Registra el saga en el flujo principal de Redux Saga
function* mySaga() {
  
}

// Exporta el saga para ser ejecutado en el archivo principal de configuración de Redux Saga
export default mySaga;