import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button, DropdownItem, Modal } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import github from '../../../assets/images/brands/github.png'
import bitbucket from '../../../assets/images/brands/bitbucket.png'
import dribbble from '../../../assets/images/brands/dribbble.png'
import dropbox from '../../../assets/images/brands/dropbox.png'
import mail_chimp from '../../../assets/images/brands/mail_chimp.png'
import slack from '../../../assets/images/brands/slack.png'
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
//i18n
import { withTranslation } from "react-i18next";
import { setCurrentCompany } from '../../../store/companies/actions'


const AppsDropdown = (props) => {

  const [modal_center, setmodal_center] = useState(false);
  const [singlebtn, setSinglebtn] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');

  useEffect(() => {
    setCompanies(props.companies)
    // console.log(companies)
  }, [props.companies])

  useEffect(() => {
    if (props.company && props.company) {
      setCompany(props.company)
    }
    else {
      setCompany('')
    }

  }, [props.company])


  if (!companies || companies.length === 0) {
    return null
  }

  const ChangeCompany = (c) => {
    props.changeCompany(c)
  }
  
  function tog_center() {
    setmodal_center(!modal_center);
  }


  return (
    <React.Fragment>


      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Agregar Estacionamiento
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Para agregar un Estacionamiento a esta cuenta, ingresa a TicketControl del Estacionamiento que te interesa agregar. Abre Configuración -> Ajstes -> Monitor e ingresa el correo electrónico de tu cuenta de TicketControl Monitor a la lista de email autorizados.
          </p>
         
        </div>
      </Modal>


      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        className="dropdown d-inline-block ms-1"
        tag="li"

      >







        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"

        >

          <div className="d-flex align-items-center">
            <div className="d-flex flex-column my-0 py-0">
              <h5 className="m-3 mt-2 mb-0  mr-4 pr-2 d-none d-md-inline-block my-0 py-0 text-info">{company.name ? company.name.toUpperCase():''}</h5>
              {company.address.length>0 && <span className="m-2 mr-4 mt-0  text-white pr-2 d-none d-md-inline-block my-0 py-0" style={{ position: 'relative', top: -4 }}>
                <small className="text-light">{company.address ? company.address.toUpperCase():''}</small>
              </span>}
            </div>
            <i className="ri-apps-2-line"></i>
          </div>
        </DropdownToggle>


        <DropdownMenu style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <div className="dropdown-header noti-title">
            <h5 className="font-size-13 text-muted text-truncate mn-0">
              Selecciona el Estacionamiento
            </h5>
          </div>

          {companies.map((company, key) => (
              
            <DropdownItem key={key} className="text-center d-grid"><Button type="button" className="btn btn-info   flex  waves-effect waves-light btn-sm" onClick={() => { ChangeCompany(company) }}>{company.name}</Button></DropdownItem>
          ))}


          <div className="dropdown-divider"></div>
          <DropdownItem className="text-center d-grid"><button
            type="button"
            className="btn btn-primary btn-sm waves-effect waves-light "
            onClick={setmodal_center}
          >
            Agregar Estacionamiento
          </button></DropdownItem>
        </DropdownMenu>


        {/* <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end ">

         

                    

          {/* <div className="">
            <SimpleBar >
            {companies.map(company => (
              <Row spacing={3}>
                  <Col lg={12} key={company.id} spacing={3}>
                    <Button small onClick={()=>{ChangeCompany(company)}}>{company.name}</Button>
                  </Col>
              </Row>
               ))}
            </SimpleBar>
          </div> */}
        {/* </DropdownMenu>  */}
      </Dropdown>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
    company: state.companies.currentCompany
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCompany: (company) => dispatch(setCurrentCompany(company)),

  };
};

// Combinación de withTranslation, connect y el componente
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AppsDropdown);