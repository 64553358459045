import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Card, CardBody, CardHeader, Row, Container, CardTitle } from "reactstrap";
import { connect } from 'react-redux';
import moments from 'moment'
import "moment/locale/es";

const TicketsStats = (props) => {

    // const customer_id = props.currentCompany.id;
    // const openedTickets = useSelector((state) => state.data.openedTickets);
    // const dispatch = useDispatch();

    const [stats, setStats] = useState(null);

    useEffect(() => {
       
        setStats(props.ticketsStats);
    }, [props.ticketsStats]);





    if (!stats) {
        return null;
    }


    return (
        <React.Fragment>
            <Row>
                
                {stats.map((item, index) => (
                    <Col key={index} className={"p-2 d-flex my-n3"} xxl={true} xl={2} lg={3} md={3} sm={6} xs={6} >
                        <Card className={item.type == 'hour' ? " card text-center flex-grow-1" : " card text-center flex-grow-1"}>
                            <CardBody className="text-center p-1 my-1 ">
                                <CardTitle><span className="align-center my-0">{item.name}</span></CardTitle>
                                <h2 className='text-success mt-0'>{item.value}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                    ))}
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        ticketsStats: state.data.ticketsStats
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TicketsStats);