import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';

import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import numeral from 'numeral'

import { connect } from 'react-redux';



const ResumeCash = (props) => {


  const resumeCashData = props.data


  if (!Array.isArray(resumeCashData)) {
    return <p>No hay datos</p>;
  }
  return (
    <React.Fragment>
      <Row>
        {resumeCashData.map((item, key) => {

          return <Col key={key} xs={12} sm={6} md={4} lg={4} xl={4} xxl={4} className="d-flex" >
            <Card outline color={key == 0 ? "primary" : "secondary"} className={key == 0 ? "border card-border-primary  flex-grow-1" : " flex-grow-1"}>
              <CardBody className="p-2  align-items-center">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    <div id="radialchart-1" className="apex-charts" dir="ltr">
                      <RadialChart num={item.percent ? parseInt(item.percent):0} className='bg' />
                    </div>
                  </div>
                  <div className="flex-grow-1  overflow-hidden align-items-center pt-1">
                    <p className="mb-1">{item.name}</p>
                    <h3 className="mb-3">{numeral(item.value).format('$ 0,0')}</h3>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        }
        )}




      </Row>
    </React.Fragment>
  );
};



const RadialChart = (props) => {
  const series = [props.num];
  const radialoptions = {
    chart: {
      type: 'radialBar',
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#0ab39c'],
    stroke: {
      lineCap: 'round'
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '72%'
        },
        track: {
          margin: 0,
        },

        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: 5,
            show: true
          }
        }
      }
    }
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={radialoptions}
        series={series}
        type="radialBar"
        height="72"
        width="72"

      />
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    // dataResumeCash: state.data.dataResumeCash, // data es la propiedad en el estado de Redux donde almacenas los datos
    // currentCompany: state.companies.currentCompany
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fetchDataResumeCash: () => dispatch(fetchDataResumeCash()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ResumeCash);

