import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Card, CardBody, CardHeader, Row, Container, CardTitle } from "reactstrap";
import { connect } from 'react-redux';
import moments from 'moment'
import "moment/locale/es";
import numeral from 'numeral'


const CC_Stats = (props) => {

    // const customer_id = props.currentCompany.id;
    // const openedTickets = useSelector((state) => state.data.openedTickets);
    // const dispatch = useDispatch();

    const [stats, setStats] = useState(null);

    useEffect(() => {
       
        const deuda = props.cc.reduce((acc, item) => acc + item.balance, 0);


        const items = [
            {
                name: 'Clientes',
                value: props.cc.length,
            },
            
            {
                name: 'Vehículos',
                value: props.cc_vehicles.length,
            },

            {
                name: 'Deuda Total',
                value: numeral(Math.abs(deuda)).format('$ 0,0')
            }
            
        ]
            
        setStats(items);
    }, [props.cc, props.cc_vehicles]);





    if (!stats) {
        return null;
    }


    return (
        <React.Fragment>
            <Row>
                
                {stats.map((item, index) => (
                    <Col key={index} className={"p-2 d-flex my-n3"} xxl={true} xl={2} lg={3} md={3} sm={6} xs={6} >
                        <Card className={item.type == 'hour' ? " card text-center flex-grow-1" : " card text-center flex-grow-1"}>
                            <CardBody className="text-center p-1 my-1 ">
                                <CardTitle><span className="align-center my-0">{item.name}</span></CardTitle>
                                <h2 className='text-success mt-0'>{item.value}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                    ))}
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        cc: state.data.cc,
        cc_vehicles: state.data.cc_vehicles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CC_Stats);