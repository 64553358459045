import {
    SET_DATA_CASH, ADD_DATA_CASH,
} from "./actionTypes"

const initialState = {
    cash_items: [],
    resume_cash_items: [],
    items: []
}

const Analyze = (items) => {

    if (items.length === 0) {
        return [[], []]
    }
    const resumes = []
    let newItemsCash = []
    let init = 0
    items.forEach(itemCash => {

        if (itemCash.type === 'init') {
            init += 1
        }

        if (init > 0) {
            let index = init - 1
            if (newItemsCash[index] == undefined) {
                newItemsCash[index] = []
            }

            newItemsCash[index].push(itemCash)


            // RESUMES
            if (itemCash.fpay != undefined && itemCash.fpay != null) {
                if (resumes[index] === undefined) {
                    resumes[index] = [];
                }

                const fpayObj = resumes[index].find(obj => obj.name === itemCash.fpay);
                if (fpayObj === undefined) {
                    resumes[index].push({ name: itemCash.fpay, value: itemCash.value });
                } else {
                    fpayObj.value += itemCash.value;
                }
            }

        }
    })



    // Calculate percent for each element in resumes
    resumes.forEach((resumesArr) => {
        const total = resumesArr.reduce((sum, itemResume) => sum + itemResume.value, 0);
        resumesArr.forEach((itemResume) => {
            itemResume.percent = (itemResume.value / total) * 100;
        });
    });

    // Agregar elemento 'Total' al inicio de cada array
    resumes.forEach(array => {
        const totalValue = array.reduce((sum, itemResume2) => sum + itemResume2.value, 0);
        array.unshift({ name: 'Total', value: totalValue, percent: 100 });
    });


    return [newItemsCash, resumes]

}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_CASH:

            const items = action.payload

            const [newItemsCash, resumes] = Analyze(items)

            return {
                ...state,
                items: action.payload, 
                cash_items: [...newItemsCash],
                resume_cash_items: [...resumes],
            }


        case ADD_DATA_CASH:
            
            const newItems2 = [...state.items, action.payload]
            const [new_cash_items, resumes2 ] = Analyze(newItems2)

            return {
                ...state,
                items: newItems2,
                cash_items: new_cash_items,
                resume_cash_items: resumes2
            }

        default:
            return state
    }
}

export default dataReducer
