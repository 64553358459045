import { FETCH_DATA_COMPANIES, SET_DATA_COMPANIES, SET_CURRENT_COMPANY, DELETE_COMPANY } from "./actionTypes"

export const fetchDataCompanies = () => ({
  type: FETCH_DATA_COMPANIES,
})

export const setDataCompanies = (data) => {
  return {
    type: SET_DATA_COMPANIES,
    payload: data
  };
};


export const setCurrentCompany = (data) => ({
  type: SET_CURRENT_COMPANY,
  payload: data
})

export const deleteCompany = (data) => ({
  type: DELETE_COMPANY,
  payload: data
})
