// actionTypes.js

export const FETCH_DATA_RESUME_CASH = 'FETCH_DATA_RESUME_CASH'
export const SET_DATA_RESUME_CASH = 'SET_DATA_RESUME_CASH'
export const UPDATE_RESUME_CASH_STATE = 'UPDATE_RESUME_CASH_STATE';

export const FETCH_OPENED_TICKETS = 'FETCH_OPENED_TICKETS'
export const SET_OPENED_TICKETS = 'SET_OPENED_TICKETS'
export const ADD_OPENED_TICKETS = 'ADD_OPENED_TICKETS'
export const UPDATE_OPENED_TICKETS = 'UPDATE_OPENED_TICKETS'
export const REMOVE_OPENED_TICKET = 'REMOVE_OPENED_TICKET'

export const FETCH_CLOSED_TICKETS = 'FETCH_CLOSED_TICKETS'
export const SET_CLOSED_TICKETS = 'SET_CLOSED_TICKETS'
export const ADD_CLOSED_TICKET = 'ADD_CLOSED_TICKET'
export const UPDATE_CLOSED_TICKETS = 'UPDATE_CLOSED_TICKETS'
export const REMOVE_CLOSED_TICKET = 'REMOVE_CLOSED_TICKET'

export const FETCH_TICKETS_STATS = 'FETCH_TICKETS_STATS'
export const SET_TICKETS_STATS = 'SET_TICKETS_STATS'
export const SET_FOREGROUND = 'SET_FOREGROUND'

export const SET_LOGINS = 'SET_LOGINS'
export const ADD_LOGINS = 'ADD_LOGINS'

export const SET_CC = 'SET_CC'

export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'

export const SET_CC_VEHICLES = 'SET_CC_VEHICLES'

export const SET_VERSION_DB = 'SET_VERSION_DB'
