import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

import Tickets from "./openedTickets";
import ClosedTickets from "./closedTickets";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import TicketsStats from "./ticketstats";

const TicketsPage = () => {
    document.title = "TicketControl - Tickets";

    const [activeTab2, setactiveTab2] = useState("5");

    const toggle2 = (tab) => {
        if (activeTab2 !== tab) {
            setactiveTab2(tab);
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TicketControl" breadcrumbItem="Tickets" />

                    {/* <CardTitle className="h4">Tickets</CardTitle> */}
                    {/* <p className="card-title-desc">Seleccione</p> */}
                    <Row>
                        <Col>
                            <TicketsStats />
                        </Col>
                    </Row>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab2 === "5",
                                })}
                                onClick={() => {
                                    toggle2("5");
                                }}
                            >
                                <i className="dripicons-home me-1 align-middle"> </i>{" "}
                                Tickets Abiertos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab2 === "6",
                                })}
                                onClick={() => {
                                    toggle2("6");
                                }}
                            >
                                <i className="dripicons-user me-1 align-middle"></i>{" "}
                                Tickets Cerrados
                            </NavLink>
                        </NavItem>


                    </Nav>

                    <TabContent activeTab={activeTab2} className="p-3 text-muted">
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <CardText className="mb-0">
                                        <Row>
                                            <Tickets />
                                        </Row>
                                    </CardText>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="6">
                            <Row>
                                <Col sm="12">
                                    <CardText className="mb-0">
                                        <Row>
                                            <ClosedTickets/>
                                        </Row>
                                    </CardText>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>



                </Container>
            </div>
        </>
    );
}



export default TicketsPage;