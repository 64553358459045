import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Card, CardBody, CardHeader, Row, Container, CardTitle, CardFooter, CardText } from "reactstrap";
import { collection, doc, onSnapshot, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";
import { fetchOpenedTickets, setOpenedTickets } from "../../store/data/actions";
import { connect } from 'react-redux';
import moment from 'moment';
import "moment/locale/es";


const Tickets = (props) => {

    const openedTickets = useSelector((state) => state.data.openedTickets);

    const [ticket, setTicket] = useState(null);

    useEffect(() => {
        setTicket(props.openedTickets[0]);
    }, [props.openedTickets]);


    // if openedTickets is not array 
    // or openedTickets is empty array
    // then return null
    if (!Array.isArray(openedTickets) || !openedTickets.length) {
        return null;
    }

    const getType = (type) => {
        switch (type) {
            case 'hour':
                return 'xHora';
            case 'stay':
                return 'xEstadia';
            case 'tn':
                return 'xTurno';
            default:
                return '--';
        }
    };


    const TimeAgo = ({ date }) => {
        moment.locale("es");
        const timeAgo = moment(date).fromNow();

        return <span>{timeAgo}</span>;
    };

    const getColor = (colorName, opacity) => {
        const colors = {
            blue: [11, 177, 151],


            indigo: [86, 74, 177],
            purple: [86, 100, 210],
            hour: [86, 100, 210],

            pink: [232, 62, 140],
            red: [255, 61, 96],
            stay: [255, 61, 96],


            orange: [241, 115, 79],

            yellow: [252, 185, 44],
            tn: [252, 185, 44],


            green: [10, 192, 116],
            tn: [10, 192, 116],

            teal: [5, 5, 5],
            cyan: [74, 163, 255],
        };

        const color = colors[colorName.toLowerCase()];

        if (!color) {
            console.warn(`Color '${colorName}' not found. Defaulting to black.`);
            return `rgba(0, 0, 0, ${opacity})`; // Default to black if color is not found
        }

        return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
    };


    if (ticket == null) {
        return null;
    }



    return (

        <Card className="flex-grow-1">
            <CardBody className="text-center">
                <h5>Último Ticket Ingresado</h5>
                <h5>{ticket.category_name} </h5>
                <h3 className='text-primary'><strong>#{ticket.plate}</strong></h3>
            </CardBody>
        </Card>
    )
    return (
        <React.Fragment>

            <Row>
                <h5 className="text-center">Último ingresado</h5>
                <Col xs={12}  >
                    <Card className={ticket.type == 'hour' ? " card text-center" : " card  text-center"} style={{ backgroundColor: getColor(ticket.type, .2) }}>
                        <CardHeader className="h4  text-uppercase " style={{ backgroundColor: getColor(ticket.type, .2) }}>
                            <h3 className="align-center  p-0 mb-0 "><strong>#{ticket.plate}</strong></h3>
                        </CardHeader>
                        <CardBody className="text-center " >
                            <h5 className=' text-uppercase'>{ticket.category_name}</h5>
                            {/* <h3 className="align-center ">#{item.plate}</h3> */}

                            {/* <CardText>
                                With supporting text below as a natural lead-in to
                                additional content.
                            </CardText> */}

                            {/* align center all content */}


                            <h4 className=''>{getType(ticket.type)}</h4>
                            {/*item.init_date  date to dd/mm/YY H:M */}

                            <div>{moment(ticket.init_date).format('DD/MM/YY HH:mm')}</div>
                            <TimeAgo date={ticket.init_date} />


                        </CardBody>
                        <CardFooter className="  card mb-0 text-white" style={{ backgroundColor: getColor(ticket.type, .2) }}><small>CAJERO: {ticket.fullName.toUpperCase()}</small></CardFooter>

                    </Card>

                </Col>

            </Row>


        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        openedTickets: state.data.openedTickets, // data es la propiedad en el estado de Redux donde almacenas los datos
        customer_id: state.data.customer_id,
        currentCompany: state.companies.currentCompany,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Tickets);